body {
    height: 100%;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.9);
    color: white;
}

h1{
    margin: 0;
    padding-top: 30px;
}

.background {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    opacity: 0.5;
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.main-container {
    width: 70%;
    margin: auto;
}

/* Start faded out with a Y offset (downwards). */
.start-faded-out-down {
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0, 0, .58, 1);
}

.start-faded-out {
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 2.2s;
    transition-timing-function: cubic-bezier(0, 0, .58, 1);
}

.loaded {
    opacity: 1;
    transform: translateY(0px);
}

.loaded.first-loaded {
    transition-delay: 0.2s;
}

.loaded.second-loaded {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 1.2s;
}

.loaded.third-loaded {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 2.2s;
}

.footer {
    bottom: 5%;
    right: 5%;
    margin: 0 auto;
    position: fixed;

    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.indexViewCount {
    margin: 10px;
}

.draggable-text-box {
    position: absolute;
    left: 50%;
    z-index: 10;

    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

    opacity: 0;
}
.loaded.draggable-text-box {
    opacity: 1;
}

.draggable-text-box-inner-text {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    width: 200px;
    text-align: center;

    margin-top: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 100px;
}
/*# sourceMappingURL=index.950ae30b.css.map */
